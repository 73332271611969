<template>
  <Dialog v-model:visible="isShow" :style="{width: '450px'}" header="套餐设置" :modal="true" class="p-fluid"
          @hide="isShow=false">
    <form @submit.prevent="handleSubmit()" class="p-fluid" style="margin-top: 32px">
      <div class="p-field p-grid">
        <label for="isp" class="p-col-fixed" style="width:100px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;">*</span>SIM运营商:
        </label>
        <div class="p-col">
          <InputText id="isp" v-model.trim="form.ispName"/>
          <small class="p-error" v-if="!form.ispName && form.$submitted">请输入SIM运营商</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="validPeriod" class="p-col-fixed" style="width:100px;justify-content: flex-end">
          <span style="color:red;margin-right: 4px;">*</span>有效期:
        </label>
        <div class="p-col">
          <Calendar id="validPeriod" placeholder="请选择" v-model="form.validPeriod" :manualInput="false" dateFormat="yy/mm/dd"
                    :show-icon="true"/>
          <small class="p-error" v-if="!form.validPeriod && form.$submitted">请输入有效期</small>
        </div>
      </div>
      <div class="p-field p-grid">
        <label for="comments" class="p-col-fixed" style="width:100px;justify-content: flex-end">
          <span style="margin-right: 4px;"></span>备注:
        </label>
        <div class="p-col">
          <Textarea id="comments" v-model.trim="form.comments" :autoResize="true" rows="4" cols="60"/>
        </div>
      </div>
      <div class="p-dialog-footer">
        <Button label="取消" class="p-button-outlined" @click="isShow=false"/>
        <Button style="margin: 0" type="submit" label="提交"/>
      </div>
    </form>
  </Dialog>
</template>

<script>

export default {
    data(){
      return {
        isShow: false,
        form: {
          ispName: null,
          validPeriod: null,
          comments: null,
          $submitted: false
        },
        rows : null
      }
    },
    methods: {
      show(rows) {
        this.rows = rows;
        for(let i in this.form){
          this.form[i] = null;
        }
        this.isShow = true;
      },
      handleSubmit() {
        this.form.$submitted = true;

        if(!this.form.ispName || !this.form.validPeriod){
          return;
        }

        let params = {};
        Object.assign(params, this.form);
        // 转换日期格式为字符串，因为数据库是统一的key-value
        params.validPeriod = new Date(this.form.validPeriod).toLocaleDateString()

        // 拼接所选设备的ids
        let devIds = "";
        for(let i in this.rows){
          let row = this.rows[i];
          // 不要使用-等，因为id中可能包含
          devIds += row.pid +"$$$"+row.did + ",";
        }
        devIds = devIds.substring(0, devIds.length - 1);
        params["devIds"] = devIds;

        this.$http("/devconf/updateConf","get",params,()=>{
          this.isShow = false;
          this.$toast.add({severity: 'success', summary: '成功', detail: '套餐设置成功！', life: 3000});
        });
      }
    }
}
</script>
<style lang="scss" scoped>
.p-dialog .p-dialog-footer{
  padding: 0;
}
</style>
